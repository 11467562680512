import Work1 from "../../assets/work-1.svg";
import Work2 from "../../assets/work-2.svg";
import Work3 from "../../assets/work-3.svg";
import Work4 from "../../assets/work-4.svg";
import Work5 from "../../assets/work-5.svg";
import Work6 from "../../assets/work-6.svg";


const Menu = [
    {
        id: 1,
        image: Work1,
        title:"Project Management Illustration",
        category: "Design",
    },
    {
        id: 2,
        image: Work2,
        title:"Gust App Walkthrough Screens",
        category: "Design",
    },
    {
        id: 3,
        image: Work3,
        title:"Delivery App Wireframe",
        category: "Branding",
    },
    {
        id: 4,
        image: Work4,
        title:"Onboarding Motivation",
        category: "Desing",
    },
    {
        id: 5,
        image: Work5,
        title:"iMac Mokup Design",
        category: "Creative",
    },
    {
        id: 6,
        image: Work6,
        title:"Game Store App Concept",
        category: "Art",
    },

];

export default Menu